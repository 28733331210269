import React, { useRef, useState, useEffect } from "react";
import { FaRegCirclePause, FaRegCirclePlay } from "react-icons/fa6";

const VideoPlayer = React.memo(({ item, index }) => {
  const videoRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);

  const handleVideoSelect = (index) => {
    setSelectedVideoIndex(index);
  };

  useEffect(() => {
    if (index === selectedVideoIndex) {
      const video = videoRef.current;
      if (video) {
        video.load();
        video
          .play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Error attempting to play the video:", error);
          });
      }
    } else {
      const video = videoRef.current;
      video?.pause();
      setIsPlaying(false);
    }
  }, [index, selectedVideoIndex]);

  const handleVideoEnd = () => {
    setSelectedVideoIndex(null);
    setIsPlaying(false);
  };
  return (
    <>
      {index !== selectedVideoIndex ? (
        <div className="relative w-full h-[200px] group">
          <img
            src={`${BASE_URL}/media/${item?.thumbnail_url}`}
            alt="Thumbnail"
            className="w-full h-full object-cover rounded-lg cursor-pointer"
          />
          <button
            className={`absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 text-white`}
            onClick={() => handleVideoSelect(index)}
          >
            {isPlaying ? (
              <FaRegCirclePause className="text-[30px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            ) : (
              <FaRegCirclePlay className="text-[30px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            )}
          </button>
        </div>
      ) : (
        <div className="relative w-full h-[200px] group">
          <video
            className={`w-full h-full object-cover rounded-lg `}
            ref={videoRef}
            controls={true}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={handleVideoEnd}
          >
            <source src={item?.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </>
  );
});

export default VideoPlayer;
