import { useContext, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";
import toast from "react-hot-toast";
import GlobalContext from "../context/GlobalContext";

function useDeleteVideo() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = getAccessToken();
  const { setUpdateResponse } = useContext(GlobalContext);
  const [DeleteVideo, setDeleteVideo] = useState({
    loading: false,
    message: null,
  });

  const RemoveFromDelete = async (videoId) => {
    setDeleteVideo((prevState) => ({
      ...prevState,
      loading: true,
      message: null,
    }));
    await axios
      .delete(`${BASE_URL}/api/video-delete/${videoId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUpdateResponse(true);
        toast.success("Video deleted successfully");
        setDeleteVideo((prevState) => ({
          ...prevState,
          loading: false,
          message: "Video deleted successfully",
        }));
      })
      .catch((error) => {
        toast.error("Oops! Something went wrong , try again in a while");
        setDeleteVideo((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong , try again in a while",
        }));
      });
  };

  return { RemoveFromDelete, DeleteVideo };
}

export default useDeleteVideo;
