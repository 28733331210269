/* eslint-disable react/jsx-pascal-case */
import React, { Fragment, useRef, useState } from "react";
import { MdOutlineClose, MdOutlineFileDownload } from "react-icons/md";
import VideoPlayer from "./VideoPlayer";
import useAddFavourite from "../hooks/useAddFavourite";
import useRemoveFavourite from "../hooks/useRemoveFavourite";
import { ButtonLoader_1 } from "./shared/Loaders";
import { FaRegHeart } from "react-icons/fa6";
import { getAccessToken } from "../storage/storage";
import { IoMdShareAlt } from "react-icons/io";
import ShareModal from "./modals/ShareModal";
import { MdOutlineDeleteOutline } from "react-icons/md";
import useDeleteVideo from "../hooks/useDeleteVedio";

const VideoCard = React.memo(
  ({ item, index, addToFav, isFav, deleteVideo }) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const token = getAccessToken();
    const { addFavourite, AddToFav } = useAddFavourite();
    const { removeFavourite, RemoveFromFav } = useRemoveFavourite();
    const { RemoveFromDelete, DeleteVideo } = useDeleteVideo();

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [videoId, setVideoId] = useState(null);

    const removeFromFavourite = async (videoId) => {
      await RemoveFromFav(videoId);
    };

    const DeleteFromVideo = async (videoId) => {
      await RemoveFromDelete(videoId);
    };

    const addToFavourite = async (videoId) => {
      await AddToFav({ id: videoId });
    };

    const handleDownload = (url) => {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    function getRelativeTime(dateString) {
      const now = new Date();
      const pastDate = new Date(dateString);
      const differenceInSeconds = Math.floor((now - pastDate) / 1000);

      if (differenceInSeconds < 60) {
        return `${differenceInSeconds}seconds ago`;
      } else if (differenceInSeconds < 3600) {
        const minutes = Math.floor(differenceInSeconds / 60);
        return `${minutes}m ago`;
      } else if (differenceInSeconds < 86400) {
        const hours = Math.floor(differenceInSeconds / 3600);
        return `${hours}${hours !== 1 ? "hrs" : "h"} ago`;
      } else if (differenceInSeconds < 2592000) {
        const days = Math.floor(differenceInSeconds / 86400);
        return `${days}${days !== 1 ? "days" : "d"} ago`;
      } else if (differenceInSeconds < 31536000) {
        const months = Math.floor(differenceInSeconds / 2592000);
        return `${months}month${months !== 1 ? "s" : ""} ago`;
      } else {
        const years = Math.floor(differenceInSeconds / 31536000);
        return `${years}${years !== 1 ? "year" : "years"} ago`;
      }
    }

    return (
      <>
        <div key={index} className={`relative mb-2`}>
          <VideoPlayer item={item} index={index} />
          {token && (
            <>
              {addToFav === true && (
                <>
                  {removeFavourite.loading ? (
                    <div className={`absolute top-2 right-2`}>
                      <ButtonLoader_1 />
                    </div>
                  ) : (
                    <button
                      title="Click here to remove from favourites"
                      onClick={() => removeFromFavourite(item?.id)}
                      className={`absolute top-2 right-2 cursor-pointer bg-[#0000009c]
              rounded-full p-[5px] hover:bg-primary`}
                    >
                      <MdOutlineClose className="text-white text-[15px]" />
                    </button>
                  )}
                </>
              )}
              {addToFav === false && (
                <>
                  {addFavourite.loading ? (
                    <div className={`absolute top-2 right-2`}>
                      <ButtonLoader_1 />
                    </div>
                  ) : (
                    <>
                      <button
                        title={`${
                          isFav
                            ? "Already added in favourites"
                            : "Click here to add in favourites"
                        }`}
                        onClick={() => {
                          if (!isFav) {
                            addToFavourite(item?.id);
                          }
                        }}
                        className={`absolute top-2 right-2  ${
                          isFav
                            ? "bg-primary cursor-default"
                            : "bg-[#0000009c] cursor-pointer"
                        }
              rounded-full p-[5px] hover:bg-primary`}
                      >
                        <FaRegHeart className="text-white text-[15px]" />
                      </button>
                    </>
                  )}
                </>
              )}
              {deleteVideo && (
                <>
                  {DeleteVideo.loading ? (
                    <div className={`absolute top-2 right-10`}>
                      <ButtonLoader_1 />
                    </div>
                  ) : (
                    <button
                      title="Click here to remove from favourites"
                      onClick={() => DeleteFromVideo(item?.id)}
                      className={`absolute top-2 right-10 cursor-pointer bg-[#0000009c]
              rounded-full p-[5px] hover:bg-primary`}
                    >
                      <MdOutlineDeleteOutline className="text-white text-[15px]" />
                    </button>
                  )}
                </>
              )}
            </>
          )}
          <div className="flex items-center justify-between pt-2">
            <div className="flex items-center gap-1">
              {item?.user?.profile_pic ? (
                // <img
                //   src={`${BASE_URL}${item?.user?.profile_pic}`}
                //   alt=""
                //   className="w-[25px] h-[25px] rounded-full"
                // />
                <svg
                  className="w-[25px] h-[25px] text-gray-200 dark:text-gray-700"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
              ) : (
                <svg
                  className="w-[25px] h-[25px] text-gray-200 dark:text-gray-700"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
              )}
              <div>
                <h1 className="text-[#1E2444] text-[12px] font-[500] leading-3">
                  {item?.user?.first_name}
                </h1>
                <p className="text-[#718096] text-[9px] font-[300]">
                  {getRelativeTime(item?.created_on)}
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <button className="text-secondary text-[25px] cursor-pointer hover:scale-125 transition-transform duration-300 ease-in-out">
                <IoMdShareAlt
                  onClick={() => {
                    setIsOpenModal(true);
                    setVideoId(item.id);
                  }}
                />
              </button>
              <button title="Click here to download video">
                <MdOutlineFileDownload
                  onClick={() => handleDownload(item.url)}
                  className="text-secondary text-[25px] cursor-pointer hover:scale-125 transition-transform duration-300 ease-in-out"
                />
              </button>
            </div>
          </div>
        </div>
        <ShareModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          videoId={videoId}
        />
      </>
    );
  }
);

export default VideoCard;
