import { useContext, useState } from "react";
import axios from "axios";

function useGetAllVideos() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [allVideos, setAllVideos] = useState({
    data: null,
    loading: true,
    message: null,
  });

  const GetAllVideos = async () => {
    await axios
      .get(`${BASE_URL}/api/get-video`)
      .then((response) => {
        setAllVideos((prevState) => ({
          ...prevState,
          loading: false,
          data: [...(response.data || [])].reverse(),
          message: response.data.length === 0 ? "No videos found" : null,
        }));
      })
      .catch((error) => {
        setAllVideos((prevState) => ({
          ...prevState,
          loading: false,
          message:
            error?.response?.data?.message || "Oops! Something went wrong",
        }));
      });
  };
  return { GetAllVideos, allVideos };
}
export default useGetAllVideos;
