import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import Cookies from "js-cookie";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  getAccessToken,
  removeAccessToken,
  removeRefreshToken,
} from "../../storage/storage";
import { OauthSender } from "react-oauth-flow";
import { Link } from "react-router-dom";
import ProfileModal from "../modals/ProfileModal";
import { GoSignOut } from "react-icons/go";

function Navbar() {
  const dropdownRef = useRef(null);
  const token = getAccessToken();
  const { userInfo } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logout = () => {
    removeAccessToken();
    removeRefreshToken();
    window.location.href = "/";
    Cookies.remove("task_id");
    Cookies.set("loading", false);
  };

  return (
    <>
      <nav className="w-full h-[65px] sm:px-6 xs:px-3 flex items-center justify-between sticky top-0 z-50 bg-white border-b-[1px] border-[#E2E8F0]">
        <div className="flex items-center">
          <Link to="/">
            <img
              src="/assets/logo/logo.png"
              alt="CrewDog"
              className="w-[100px]"
            />
          </Link>
        </div>
        {token ? (
          <div className="flex items-center gap-6">
            <div className="relative">
              <IoMdNotificationsOutline className="text-[25px] hover:text-secondary cursor-pointer" />
              <div className="absolute top-[-2px] right-0 h-2 w-2 bg-secondary rounded-full notification-blink"></div>
            </div>
            <div className="sm:flex items-center gap-3 px-4 border-l-[1px] border-r-[1px] border-[#cecece] xs:hidden">
              <img src="/assets/icons/1.png" alt="" />
              <p className="text-[#1E2444] text-[14px] font-[600] leading-5 cursor-pointer hover:text-secondary">
                Upgrade Plan
              </p>
            </div>
            {userInfo?.profile_pic ? (
              <div className="relative" ref={dropdownRef}>
                <ProfileMenu logout={logout} />
              </div>
            ) : (
              <div className="relative" ref={dropdownRef}>
                <ProfileMenu />
              </div>
            )}
          </div>
        ) : (
          <OauthSender
            clientId={process.env.REACT_APP_BASE_URL_CLIENTID}
            authorizeUrl={`${process.env.REACT_APP_BASE_URL_SSO}/authorize`}
            redirectUri={process.env.REACT_APP_BASE_URL_REDIRECT_URL}
            render={({ url }) => (
              <a
                href={url}
                className="py-2 px-6 flex justify-center items-center rounded-[4px] text-[20px] font-medium text-white"
                style={{
                  backgroundImage:
                    "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
                }}
              >
                Sign In
              </a>
            )}
          />
        )}
      </nav>
    </>
  );
}

export default Navbar;

// function ProfileMenu({ logout }) {
//   const url = process.env.REACT_APP_BASE_URL_SSO;
//   const { userInfo } = useContext(GlobalContext);
//   const [showmodel, setShowmodel] = useState(false);
//   const [showDropdown, setShowDropdown] = useState(false);

//   const dropdownRef = useRef();

//   const handleSmallCircleClick = () => {
//     setShowDropdown(!showDropdown);
//   };
//   const handleCloseDropdown = () => {
//     setShowDropdown(false);
//   };
//   const handleManageProfileClick = () => {
//     setShowmodel(true);
//     setShowDropdown(false);
//   };

//   useEffect(() => {
//     const handleOutsideClick = (e) => {
//       if (
//         e.target.closest(".profile-dropdown") ||
//         e.target.closest(".small-circle") ||
//         (dropdownRef.current && dropdownRef.current.contains(e.target))
//       ) {
//         return;
//       }
//       handleCloseDropdown();
//     };
//     document.addEventListener("mousedown", handleOutsideClick);

//     return () => {
//       document.removeEventListener("mousedown", handleOutsideClick);
//     };
//   }, []);

//   const handleLogout = () => {
//     removeAccessToken();
//     removeRefreshToken();
//     window.location.href = "/";
//   };
//   return (
//     <>
//       <div
//         className="small-circle relative cursor-pointer"
//         onClick={handleSmallCircleClick}
//       >
//         {userInfo?.profile_pic && (
//           <img
//             className="w-[55px] h-[50px] rounded-full cursor-pointer"
//             src={`${url}${userInfo?.profile_pic}`}
//             // src={`https://sso.crewdog.ai${userInfo?.profile_pic}`}
//             alt="logo"
//           />
//         )}
//         {!userInfo?.profile_pic && (
//           // <img className="w-[55px] h-[50px]" src={Avatar} alt="logo" />
//           <svg
//             className="w-[45px] h-[45px] text-gray-200 dark:text-gray-700 cursor-pointer"
//             aria-hidden="true"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="currentColor"
//             viewBox="0 0 20 20"
//             // onClick={() => setDropdownOpen(!dropdownOpen)}
//           >
//             <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
//           </svg>
//         )}
//       </div>
//       {showDropdown && (
//         <div className="absolute profile-dropdown">
//           <div className="fixed top-[60px] right-5 bg-[#fafafa] border border-[#ddd] rounded-[10px] z-[60] mt-0  lg:w-[300px] lg:h-[340px] ">
//             <div
//               style={{
//                 padding: "10px 10px 7px 10px",
//               }}
//             >
//               <div className="bg-[#ffffff] lg:w-[280px] lg:h-[230px] flex flex-col justify-center items-center pt-2 gap-2 rounded">
//                 <div className="medium-circle">
//                   {userInfo?.profile_pic && (
//                     <img
//                       className="w-[80px] h-[80px] rounded-full "
//                       src={`${url}${userInfo?.profile_pic}`}
//                       alt="logo"
//                     />
//                   )}
//                   {!userInfo?.profile_pic && (
//                     <svg
//                       className="w-[45px] h-[45px] text-gray-200 dark:text-gray-700 cursor-pointer"
//                       aria-hidden="true"
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="currentColor"
//                       viewBox="0 0 20 20"
//                       // onClick={() => setDropdownOpen(!dropdownOpen)}
//                     >
//                       <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
//                     </svg>
//                   )}
//                 </div>
//                 <h1 className="text-2xl font-semibold font-[Poppins]">
//                   {userInfo?.first_name || userInfo?.last_name ? (
//                     <>
//                       {userInfo?.first_name} {userInfo?.last_name}
//                     </>
//                   ) : (
//                     <>
//                       {userInfo?.first_name} {userInfo?.last_name}
//                     </>
//                   )}
//                 </h1>
//                 <div
//                   onClick={handleManageProfileClick}
//                   className="border border-primary py-[10px] px-[20px] text-[16px]  rounded-[140px] cursor-pointer"
//                 >
//                   Manage Profile
//                 </div>
//               </div>
//             </div>
//             <div
//               style={{
//                 padding: "2px 10px",
//               }}
//               onClick={handleLogout}
//             >
//               <div className="w-[280px] h-[80px] bg-[#ffff] flex justify-center items-center cursor-pointer rounded gap-[20px]">
//                 {/* <img className="w-[25px] h-[30px]" src={signout} alt="" /> */}
//                 <p
//                   onClick={logout}
//                   className="font-normal text-[#292929] text-base"
//                 >
//                   Sign Out
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       <ProfileModal showmodel={showmodel} setShowmodel={setShowmodel} />
//     </>
//   );
// }

function ProfileMenu({ logout }) {
  const url = process.env.REACT_APP_BASE_URL_SSO;
  const { userInfo } = useContext(GlobalContext);
  const [showmodel, setShowmodel] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef();

  const handleSmallCircleClick = () => {
    setShowDropdown(!showDropdown);
  };
  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };
  const handleManageProfileClick = () => {
    setShowmodel(true);
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.closest(".profile-dropdown") ||
        e.target.closest(".small-circle") ||
        (dropdownRef.current && dropdownRef.current.contains(e.target))
      ) {
        return;
      }
      handleCloseDropdown();
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleLogout = () => {
    removeAccessToken();
    removeRefreshToken();
    window.location.href = "/";
  };
  return (
    <>
      <div
        className="small-circle relative cursor-pointer"
        onClick={handleSmallCircleClick}
      >
        {userInfo?.profile_pic && (
          <img
            className="w-[55px] h-[50px]"
            src={`${url}${userInfo?.profile_pic}`}
            // src={`https://sso.crewdog.ai${userInfo?.profile_pic}`}
            alt="logo"
          />
        )}
        {!userInfo?.profile_pic && (
          <img
            className="w-[55px] h-[50px]"
            src="/assets/images/avatar.png"
            alt="logo"
          />
        )}
      </div>
      {showDropdown && (
        <div className="absolute profile-dropdown">
          <div className="fixed top-[60px] right-5 bg-[#fafafa] border border-[#ddd] rounded-[10px] z-[60] mt-0  lg:w-[300px] lg:h-[340px] ">
            <div
              style={{
                padding: "10px 10px 7px 10px",
              }}
            >
              <div className="bg-[#ffffff] lg:w-[280px] lg:h-[230px] flex flex-col justify-center items-center pt-2 gap-2 rounded">
                <div className="medium-circle">
                  {userInfo?.profile_pic && (
                    <img
                      className="w-[94px] h-[94px]"
                      src={`${url}${userInfo?.profile_pic}`}
                      alt="logo"
                    />
                  )}
                  {!userInfo?.profile_pic && (
                    <img
                      className="w-[94px] h-[94px]"
                      src="/assets/images/avatar.png"
                      alt="logo"
                    />
                  )}
                </div>
                <h1 className="text-2xl font-semibold font-[Poppins]">
                  {userInfo?.first_name || userInfo?.last_name ? (
                    <>
                      {userInfo?.first_name} {userInfo?.last_name}
                    </>
                  ) : (
                    <>
                      {" "}
                      {userInfo?.first_name} {userInfo?.last_name}
                    </>
                  )}
                </h1>
                <div
                  onClick={handleManageProfileClick}
                  className="border border-primary py-[10px] px-[20px] text-[16px]  rounded-[140px] cursor-pointer"
                >
                  Manage Profile
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "2px 10px",
              }}
              onClick={handleLogout}
            >
              <div
                onClick={logout}
                className="w-[280px] h-[80px] bg-[#ffff] flex justify-center items-center cursor-pointer rounded gap-[20px]"
              >
                <GoSignOut />
                <p className="font-normal text-[#292929] text-base">Sign Out</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <ProfileModal showmodel={showmodel} setShowmodel={setShowmodel} />
    </>
  );
}
