import { useContext, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";
import toast from "react-hot-toast";
import GlobalContext from "../context/GlobalContext";

function useRemoveFavourite() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = getAccessToken();
  const { setUpdateResponse } = useContext(GlobalContext);
  const [removeFavourite, setRemoveFavourite] = useState({
    loading: false,
    message: null,
  });

  const RemoveFromFav = async (videoId) => {
    setRemoveFavourite((prevState) => ({
      ...prevState,
      loading: true,
      message: null,
    }));
    await axios
      .delete(`${BASE_URL}/api/favorite/${videoId}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUpdateResponse(true);
        toast.success("Video removed from favourites");
        setRemoveFavourite((prevState) => ({
          ...prevState,
          loading: false,
          message: "Video removed from favourites",
        }));
      })
      .catch((error) => {
        toast.error("Oops! Something went wrong , try again in a while");
        setRemoveFavourite((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong , try again in a while",
        }));
      });
  };

  return { RemoveFromFav, removeFavourite };
}

export default useRemoveFavourite;
