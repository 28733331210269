import { useState } from "react";
import axios from "axios";
import useGetUserInfo from "./useGetUserInfo";
import { getAccessToken } from "../storage/storage";
import toast from "react-hot-toast";

function useManageProfile() {
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const token = getAccessToken();
  const [loading, setLoading] = useState(false);
  const { getCurrentUser } = useGetUserInfo();

  const manageProfile = async (payload, setShowmodel) => {
    setLoading(true);
    await axios
      .put(`${BASE_URL}/api/accounts/profile/update/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        getCurrentUser(token);
        setShowmodel(false);
        setLoading(false);

        toast.success("You have successfully updated profile!");
      })
      .catch((error) => {
        setLoading(false);

        toast.error(
          "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
        );
      });
  };
  return { manageProfile, loading };
}

export default useManageProfile;
