/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";

import { useFormik } from "formik";
import useManageProfile from "../../hooks/useManageProfile";
import { RiEditFill } from "react-icons/ri";
import GlobalContext from "../../context/GlobalContext";
import { BiHide, BiShow } from "react-icons/bi";
import * as Yup from "yup";
import useChangePassword from "../../hooks/useChangepassword";
import FloatingInput from "../FloatingInput";
import { ButtonLoader_1 } from "../shared/Loaders";
// import { ButtonLoader2 } from "../ButtonLoader";

const ChangePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .required("New password is required")
    .min(6, "Password length must be at least 6 characters long!")
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      "Password must contain at least one alphabetic character and one special character"
    )
    .max(20, "password must not exceed 20 characters"),
  confirm_password: Yup.string()
    .required("Confirm new password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

const UpdateProfileValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("Firstname is required"),
  last_name: Yup.string(),
  email: Yup.string().required("Email is required"),
});

const Manageprofile = ({ showmodel, setShowmodel }) => {
  const cancelButtonRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!showmodel) {
      const timer = setTimeout(() => {
        setActiveTab(0);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showmodel]);

  return (
    <>
      <Transition.Root show={showmodel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 "
          initialFocus={cancelButtonRef}
          onClose={setShowmodel}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black bg-opacity-75 ">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all lg:w-[500px] xs:w-[500px] h-auto p-2 py-8">
                  <div className="absolute top-4 right-4 cursor-pointer">
                    <AiOutlineClose onClick={() => setShowmodel(false)} />
                  </div>
                  <div className="w-full flex justify-center">
                    <div className="flex flex-col justify-center items-center w-full">
                      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
                      {activeTab === 0 && (
                        <Edit_Profile setShowmodel={setShowmodel} />
                      )}
                      {activeTab === 1 && (
                        <ChangePassword setShowmodel={setShowmodel} />
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default Manageprofile;

const Tabs = ({ activeTab, setActiveTab }) => {
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <div className=" flex justify-center w-full mb-2">
        <div className="flex items-center justify-between gap-2  border border-[#EDEDED] 2xl:h-[45px] lg:h-[45px] xs:h-[39px] p-1 w-[80%] bg-[#F8F8F8] rounded-[30px] text-gray-500 dark:text-gray-400 ">
          <button
            style={{
              boxShadow:
                activeTab === 0
                  ? "0px 2px 10px 0px rgba(0, 0, 0, 0.10)"
                  : "none",
            }}
            className={`inline-flex items-center outline-none justify-center w-[50%] h-full rounded-[40px] text-[14px] ${
              activeTab === 0 ? "bg-white" : "bg-none"
            }`}
            onClick={() => handleTabClick(0)}
          >
            Update Profile
          </button>

          <button
            style={{
              boxShadow:
                activeTab === 1
                  ? "0px 2px 10px 0px rgba(0, 0, 0, 0.10)"
                  : "none",
            }}
            className={`inline-flex items-center outline-none justify-center w-[50%] h-full  rounded-[40px] text-[14px] ${
              activeTab === 1 ? "bg-white" : "bg-none"
            }`}
            onClick={() => handleTabClick(1)}
          >
            Change Password
          </button>
        </div>
      </div>
    </>
  );
};

const Edit_Profile = ({ setShowmodel }) => {
  const { userInfo } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const { manageProfile, loading } = useManageProfile();
  const [imageSrc, setImageSrc] = useState(null);
  const [fileError, setFileError] = useState(null);

  const initialValues = {
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    email: userInfo?.email,
    profile_pic: userInfo?.profile_pic,
  };
  const { errors, values, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: UpdateProfileValidationSchema,
      onSubmit: async (values) => {
        if (imageSrc) {
          await manageProfile(
            {
              first_name: values.first_name,
              last_name: values.last_name,
              profile_pic: values.profile_pic,
            },
            setShowmodel
          );
        } else {
          await manageProfile(
            {
              first_name: values.first_name,
              last_name: values.last_name,
            },
            setShowmodel
          );
        }
      },
    });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "jfif"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isValidFileType = allowedFileTypes.includes(fileExtension);

      if (!isValidFileType) {
        setImageSrc(null);
        setFieldValue("file", null);
        setFileError("Upload only image");
        return;
      }

      setFieldValue("profile_pic", file);
      setFileError(null);
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleClose = () => {
    setShowmodel(false);
  };
  return (
    <>
      <div className="large-circle relative my-4 border-[px] border-[blue] rounded-full">
        {imageSrc ? (
          <img
            className="rounded-full w-full h-full"
            src={imageSrc}
            style={{ aspectRatio: "1/1", objectFit: "cover" }}
            alt=""
          />
        ) : (
          <>
            {userInfo?.profile_pic && (
              <img
                className="rounded-[100px] w-[200px] mt-2"
                src={`${BASE_URL}${userInfo?.profile_pic}`}
                alt="logo"
              />
            )}
            {!userInfo?.profile_pic && (
              <img
                className="rounded-[100px] w-[200px] mt-2"
                src="/assets/images/avatar.png"
                alt="logo"
              />
            )}
          </>
        )}
        <div className="flex items-center justify-center bg-[#e0f0ef] rounded-full w-[50px] h-[50px] border-[2px] border-primary absolute left-[80px] bottom-[-33px] cursor-pointer ">
          <label htmlFor="profile_pic">
            <RiEditFill className="edit cursor-pointer" />
          </label>
          <input
            type="file"
            id="profile_pic"
            name="profile_pic"
            accept=".jpg, .png, .jpeg, .jfif"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
      </div>
      {fileError && <p className="text-red-700 text-xs mb-2">{fileError}</p>}
      <div className="w-full flex flex-col lg:px-12 xs:px-2">
        <div className="mt-2">
          <FloatingInput
            label="First Name"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
            className="border border-[#9D9D9D] h-[62px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent  rounded-lg"
          />
          {errors.first_name && (
            <p className="text-red-700 text-xs">{errors.first_name}</p>
          )}
        </div>
        <div className="mt-4">
          <FloatingInput
            label="Last Name"
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
            className="border border-[#9D9D9D] h-[62px] w-[100%] px-2 text-[#5A5A5A] bg-transparent rounded-lg"
          />{" "}
          {errors.last_name && (
            <p className="text-red-700 text-xs">{errors.last_name}</p>
          )}
        </div>
        <div className="mt-4">
          <input
            label="Email"
            name="email"
            value={values.email}
            readOnly
            className="border border-[#9D9D9D] w-[100%] h-[50px] px-2 text-[#5A5A5A] bg-transparent rounded-lg"
          />
          {errors.email && (
            <p className="text-red-700 text-xs">{errors.email}</p>
          )}
        </div>
      </div>
      <div className="flex justify-end w-full gap-2 lg:px-12 xs:px-2 mt-5 cursor-pointer text-white">
        <button
          onClick={handleClose}
          className="bg-[#f0f0f0d2] w-[120px] h-[45px] rounded-[10px] text-black"
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={handleSubmit}
          className="bg-primary font-[600] text-white w-[120px] h-[45px] rounded-[10px] flex justify-center items-center"
        >
          {loading ? <ButtonLoader_1 /> : "Save"}
        </button>
      </div>
    </>
  );
};

const ChangePassword = ({ setShowmodel }) => {
  const { loading, ChangePassword } = useChangePassword();
  const [oldPassowrdVisible, setOldPassowrdVisible] = useState(false);
  const [passowrdVisible, setPassowrdVisible] = useState(false);
  const [confirmPassowrdVisible, setConfirmPassowrdVisible] = useState(false);
  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };
  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: async (values, action) => {
      await ChangePassword(
        {
          old_password: values.old_password,
          new_password: values.password,
        },
        action,
        setShowmodel
      );
    },
  });
  return (
    <div className="h-auto w-full flex flex-col justify-center items-center relative lg:shadow-profile xs:shadow-profile lg:px-12 xs:px-2 mt-8">
      <div className="w-full mt-0">
        <div className="relative">
          <FloatingInput
            label="Current password"
            type={oldPassowrdVisible ? "text" : "password"}
            name="old_password"
            value={values.old_password}
            onChange={handleChange}
            className="border border-[#9D9D9D] w-[100%] h-[62px] px-2 text-[#5A5A5A] bg-transparent rounded-lg"
          />
          <div
            className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
            onClick={() => setOldPassowrdVisible(!oldPassowrdVisible)}
          >
            {oldPassowrdVisible ? (
              <BiShow className="text-2xl text-[#95969D]" />
            ) : (
              <BiHide className="text-2xl text-[#95969D]" />
            )}
          </div>
        </div>
        {errors.old_password && touched.old_password && (
          <p className="text-red-700 text-xs">{errors.old_password}</p>
        )}
      </div>

      <div className="w-full mt-4">
        <div className="relative">
          <FloatingInput
            label="New password"
            type={passowrdVisible ? "text" : "password"}
            name="password"
            value={values.password}
            onChange={handleChange}
            className="border border-[#9D9D9D] w-[100%] h-[62px] px-2 text-[#5A5A5A] bg-transparent rounded-lg"
          />
          <div
            className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
            onClick={() => setPassowrdVisible(!passowrdVisible)}
          >
            {passowrdVisible ? (
              <BiShow className="text-2xl text-[#95969D]" />
            ) : (
              <BiHide className="text-2xl text-[#95969D]" />
            )}
          </div>
        </div>
        {errors.password && touched.password && (
          <p className="text-red-700 text-xs">{errors.password}</p>
        )}
      </div>
      <div className="w-full mt-4">
        <div className="relative">
          <FloatingInput
            label="Confirm new password"
            type={confirmPassowrdVisible ? "text" : "password"}
            name="confirm_password"
            value={values.confirm_password}
            onChange={handleChange}
            className="border border-[#9D9D9D] w-[100%] h-[62px] px-2 text-[#5A5A5A] bg-transparent rounded-lg"
          />
          <div
            className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
            onClick={() => setConfirmPassowrdVisible(!confirmPassowrdVisible)}
          >
            {confirmPassowrdVisible ? (
              <BiShow className="text-2xl text-[#95969D]" />
            ) : (
              <BiHide className="text-2xl text-[#95969D]" />
            )}
          </div>
        </div>
        {errors.confirm_password && touched.confirm_password && (
          <p className="text-red-700 text-xs">{errors.confirm_password}</p>
        )}
      </div>

      <div className="flex justify-end w-full gap-2  mt-5 cursor-pointer text-white">
        <button
          onClick={() => setShowmodel(false)}
          type="submit"
          className="bg-[#f0f0f0d2] w-[120px] h-[45px] rounded-[10px] text-black"
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={handleSubmit}
          className="bg-primary font-[600] text-white w-[120px] h-[45px] rounded-[10px] flex justify-center items-center"
        >
          {loading ? <ButtonLoader_1 /> : "Save"}
        </button>
      </div>
    </div>
  );
};
