import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { getAccessToken } from "../storage/storage";

const useChangePassword = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const [loading, setLoading] = useState(false);
  const token = getAccessToken();

  const ChangePassword = async (payload, action, setShowmodel) => {
    setLoading(true);
    await axios
      .put(`${BASE_URL}/api/accounts/password/change/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setShowmodel(false);
        action.resetForm();
        toast.success("Your password successfully changed!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.detail ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
        );
      });
  };

  return { loading, ChangePassword };
};

export default useChangePassword;
