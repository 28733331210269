import React from "react";
import { Helmet } from "react-helmet";
import config from "../config";

function AppHead({ title, description, thumbnail, url }) {
  const { seo } = config;
  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <title>{title ? title : seo.title}</title>
      <meta
        property="og:description"
        content={description ? description : seo.description}
      />
      <meta property="og:url" href={url} />
      <meta property="og:image" content={thumbnail} />
    </Helmet>
  );
}

export default AppHead;
