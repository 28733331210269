import { useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";

function useGetUserVideos() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const token = getAccessToken();
  const [userVideos, setUserVideos] = useState({
    data: null,
    loading: false,
    message: null,
  });

  const GetUserVideos = async () => {
    await axios
      .get(`${BASE_URL}/api/my-videos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserVideos((prevState) => ({
          ...prevState,
          loading: false,
          data: [...(response.data || [])].reverse(),
          message: response.data.length === 0 ? "No videos found" : null,
        }));
      })
      .catch((error) => {
        setUserVideos((prevState) => ({
          ...prevState,
          loading: false,
          message:
            error?.response?.data?.message || "Oops! Something went wrong",
        }));
      });
  };
  return { GetUserVideos, userVideos };
}
export default useGetUserVideos;
