import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";

function useGetGenerateVideo() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const Navigate = useNavigate();
  const { setUpdateResponse } = useContext(GlobalContext);

  const GetGenerateVideo = async (taskId, userId) => {
    await axios
      .get(`${BASE_URL}/api/create-video/?task_id=${taskId}&user_id=${userId}`)
      .then((response) => {
        if (response.data.id) {
          Cookies.remove("task_id");
          Navigate(`/videos/${response.data.id}`);
        }
      })
      .catch((error) => {
        toast.error("Sorry, I cannot assist you with this prompt");
        Cookies.remove("task_id");
        setUpdateResponse(true);
      });
  };

  return { GetGenerateVideo };
}

export default useGetGenerateVideo;
